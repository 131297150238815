import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
import Twocol from "../components/strapi/structure/TwoCol";
import Craftsmanship from "../components/strapi/structure/Craftsmanship";
import Outropiggy from "../components/strapi/structure/Outropiggy";

export default function HotTubs({ data }) {
  return (
    <Layout>
      <SEO title="Hot Tubs" />
      {data.allStrapiSeries700.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.s700_mh.mh_title}
            mhsubtitle={node.s700_mh.mh_subtitle}
            mhbackground={node.s700_mh.mh_bg.publicURL}
          />
          <Twocol
            title={node.s700_model710.txtimg_title}
            description={node.s700_model710.txtimg_description}
            blueTitle={node.s700_model710.txtimg_bluetitle}
            blueLink={node.s700_model710.txtimg_bluelink}
            order={node.s700_model710.txtimg_order}
            rowimg={node.s700_model710.txtimg_img.publicURL}
            start_price={node.s700_model710.start_price}
          />
          <Twocol
            title={node.s700_model720.txtimg_title}
            description={node.s700_model720.txtimg_description}
            blueTitle={node.s700_model720.txtimg_bluetitle}
            blueLink={node.s700_model720.txtimg_bluelink}
            bgColor={node.s700_model720.txtimg_bgcolor}
            order={node.s700_model720.txtimg_order}
            rowimg={node.s700_model720.txtimg_img.publicURL}
            start_price={node.s700_model720.start_price}
          />
          <Twocol
            title={node.s700_model730.txtimg_title}
            description={node.s700_model730.txtimg_description}
            blueTitle={node.s700_model730.txtimg_bluetitle}
            blueLink={node.s700_model730.txtimg_bluelink}
            bgColor={node.s700_model730.txtimg_bgcolor}
            order={node.s700_model730.txtimg_order}
            rowimg={node.s700_model730.txtimg_img.publicURL}
            start_price={node.s700_model730.start_price}
          />
          <Twocol
            title={node.s700_model740.txtimg_title}
            description={node.s700_model740.txtimg_description}
            blueTitle={node.s700_model740.txtimg_bluetitle}
            blueLink={node.s700_model740.txtimg_bluelink}
            bgColor={node.s700_model740.txtimg_bgcolor}
            order={node.s700_model740.txtimg_order}
            rowimg={node.s700_model740.txtimg_img.publicURL}
            start_price={node.s700_model740.start_price}
          />
          <Twocol
            title={node.s700_model750.txtimg_title}
            description={node.s700_model750.txtimg_description}
            blueTitle={node.s700_model750.txtimg_bluetitle}
            blueLink={node.s700_model750.txtimg_bluelink}
            bgColor={node.s700_model750.txtimg_bgcolor}
            order={node.s700_model750.txtimg_order}
            rowimg={node.s700_model750.txtimg_img.publicURL}
            start_price={node.s700_model750.start_price}
          />
            <Twocol
            title={node.s700_model750SLB.txtimg_title}
            description={node.s700_model750SLB.txtimg_description}
            blueTitle={node.s700_model750SLB.txtimg_bluetitle}
            blueLink={node.s700_model750SLB.txtimg_bluelink}
            bgColor={node.s700_model750SLB.txtimg_bgcolor}
            order={node.s700_model750SLB.txtimg_order}
            rowimg={node.s700_model750SLB.txtimg_img.publicURL}
            start_price={node.s700_model750SLB.start_price}
          />
          <Craftsmanship
            title={node.s700_craftsmanship.craft_title}
            description={node.s700_craftsmanship.craft_description}
          />
          <Outropiggy outro={node.s700_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query Series700 {
    allStrapiSeries700 {
      edges {
        node {
          id # id of the node
          s700_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          s700_craftsmanship {
            id
            craft_title
            craft_description
          }
          s700_outro {
            id
            piggy_outro
          }
          s700_model710 {
            id
            txtimg_title
            txtimg_bgcolor
            txtimg_description
            txtimg_bluelink
            start_price
            txtimg_bluetitle
            start_price
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          s700_model720 {
            id
            txtimg_title
            txtimg_bgcolor
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            start_price
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          s700_model730 {
            id
            txtimg_title
            txtimg_bgcolor
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            start_price
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          s700_model740 {
            id
            txtimg_title
            txtimg_bgcolor
            txtimg_description
            txtimg_bluelink
            txtimg_bluetitle
            start_price
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          s700_model750 {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_bluelink
            txtimg_bluetitle
            start_price
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          s700_model750SLB {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_bluelink
            txtimg_bluetitle
            start_price
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
        }
      }
    }
  }
`;
